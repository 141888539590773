<template>
    <fieldset :aria-labelledby="getId()">
        <legend class="block text-black text-regular mb-2">
            {{ field.label }}
            <span v-if="field.required" class="text-red-500">*</span>
        </legend>

        <field-instructions :field="field" position="fieldset-start" />

        <div class="mt-2 space-y-2">
            <div
                v-for="(option, index) in field.options"
                :key="index"
                class="flex items-center"
            >
                <input
                    :id="getId([index])"
                    :name="getName()"
                    :value="option.value"
                    :required="field.required"
                    type="radio"
                    class="h-4 w-4"
                />

                <label
                    :for="getId([index])"
                    class="ml-3 block text-regular text-black"
                >
                    {{ option.label }}
                </label>
            </div>
        </div>

        <field-instructions :field="field" position="fieldset-end" />
    </fieldset>
</template>

<script>
import FieldMixin from "@src/forms/mixins/FieldMixin";

export default {
    mixins: [FieldMixin],
};
</script>
