//
// Credit to Dave Stockley (magicspon)
// https://github.com/magicspon
//

import gql from "graphql-tag";
import * as FormDataJson from "form-data-json-convert";
import { flatMap, isPlainObject } from "lodash-es";

export const getFormFieldMeta = (form) => {
    const allRows = flatMap(form.pages, "rows");
    const allFields = flatMap(allRows, "rowFields");
    const fields = flatMap(allFields, ({ handle, inputTypeName }) => {
        return { handle, inputTypeName };
    });

    return fields;
};

function createMutationHandle(form) {
    return `save_${form.handle}_Submission`;
}

function createMutationTypes(form) {
    const types = getFormFieldMeta(form).map(({ handle, inputTypeName }) => {
        return `$${handle}: ${inputTypeName}`;
    });

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        types.push(`$${captcha.handle}: FormieCaptchaInput`);
    });

    return types.join(", ");
}

function createMutationValues(form) {
    const values = flatMap(getFormFieldMeta(form), "handle").map((key) => {
        return `${key}: $${key}`;
    });

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        values.push(`${captcha.handle}: $${captcha.handle}`);
    });

    return values.join(", ");
}

export const getFormMutation = (form, siteId) => {
    const mutationTypes = createMutationTypes(form);
    const mutationHandle = createMutationHandle(form);
    const mutationValues = createMutationValues(form);

    return gql`
        mutation FormMutation(${mutationTypes}) {
            ${mutationHandle}(${mutationValues}, siteId:${siteId}) {
                id
            }
        }
    `;
};

export const getMutationVariables = async (form, el) => {
    const formData = new FormData(el);

    const formWithFilePromise = new Promise<Object>((resolve) => {
        FormDataJson.toJson(el, {
            filesCallback: function (values) {
                resolve(values);
            },
        });
    });
    const promiseWithoutFile = new Promise<Object>((resolve) => {
        setTimeout(() => {
            resolve(FormDataJson.toJson(el));
        }, 200);
    });

    // Wait for the form to be converted to JSON, we have to do this,
    // because when the form has no files, the first promise will never resolve
    const formObject = await Promise.race([
        formWithFilePromise,
        promiseWithoutFile,
    ]);

    // Get the mutation types to ensure we cast everything properly
    const mutationTypes = getFormFieldMeta(form);

    for (const info of mutationTypes) {
        let value = formObject[info.handle];

        if (typeof value === "undefined") {
            continue;
        }

        // Fix up any objects that look like arrays
        if (isPlainObject(value)) {
            if (typeof value[0] !== "undefined") {
                value = Object.values(value);
            }
        }

        if (info.inputTypeName === "Int") {
            value = parseInt(formObject[info.handle], 10);
        }

        if (info.inputTypeName === "[Int]") {
            if (isPlainObject(value)) {
                value = Object.values(value);
            }

            value = value.map((item) => {
                return parseInt(item, 10);
            });
        }

        if (info.inputTypeName === "Number") {
            value = Number(formObject[info.handle]);
        }

        if (info.inputTypeName === "[Number]") {
            if (isPlainObject(value)) {
                value = Object.values(value);
            }

            value = value.map((item) => {
                return Number(item);
            });
        }

        formObject[info.handle] = value;
    }

    const fileHashMap = new Map();

    for (const entry of formData.entries()) {
        const [key, value] = entry;

        if (value instanceof File) {
            const reader = new FileReader();
            reader.readAsDataURL(value);

            const base64String = await new Promise<string>((resolve) => {
                reader.onload = () => {
                    resolve(reader.result.toString());
                };
            });

            fileHashMap.set(base64String, [
                {
                    filename: value.name,
                    fileData: base64String,
                },
            ]);
        }
    }

    for (const key in formObject) {
        if (Object.prototype.hasOwnProperty.call(formObject, key)) {
            const element = formObject[key];

            if (typeof element === "string" && fileHashMap.has(element)) {
                formObject[key] = fileHashMap.get(element);
            }

            if (isPlainObject(element)) {
                if (element.rows) {
                    for (const row of element.rows) {
                        for (const key in row) {
                            const fieldValue = row[key];

                            if (!fileHashMap.has(fieldValue)) continue;
                            row[key] = fileHashMap.get(fieldValue);
                        }
                    }
                } else {
                    for (const [key, value] of Object.entries(element)) {
                        const fieldValue = element[key];

                        if (!fileHashMap.has(fieldValue)) continue;
                        element[key] = fileHashMap.get(fieldValue);
                    }
                }
            }
        }
    }

    // Add in any captcha tokens generated when we queried the form.
    form.captchas.forEach((captcha) => {
        formObject[captcha.handle] = {
            name: captcha.name,
            value: captcha.value,
        };
    });

    return formObject;
};
