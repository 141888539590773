<template>
    <div
        v-if="canInclude"
        v-show="canShow"
        class="w-full flex-1 mb-8"
        v-bind="attrs"
    >
        <component :is="field.displayName" :field="field" />
    </div>
</template>

<script>
import { attributesTable } from "@src/forms/utils/helpers";

import AllInputs from "@form-components/inputs";

// Import separately, as they can't be nested
import Group from "@form-components/inputs/Group.vue";
import Repeater from "@form-components/inputs/Repeater.vue";

export default {
    components: {
        ...AllInputs,
        Group,
        Repeater,
    },

    props: {
        field: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        canInclude() {
            return this.field.visibility !== "disabled";
        },

        canShow() {
            return (
                this.field.visibility !== "hidden" &&
                this.field.displayName !== "Hidden"
            );
        },

        attrs() {
            return {
                class: this.field.cssClasses,
                ...attributesTable(this.field.containerAttributes),
            };
        },
    },
};
</script>
