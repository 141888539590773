<template>
    <input v-bind="attrs()" type="hidden" :value="field.defaultValue" />
</template>

<script>
import FieldMixin from "@src/forms/mixins/FieldMixin";

export default {
    mixins: [FieldMixin],
};
</script>
