<template>
    <field-label :id="getId()" :field="field" position="above-input" />

    <field-instructions :field="field" position="above-input" />

    <select
        :id="getId()"
        :name="getName()"
        :multiple="field.multiple"
        :required="field.required"
        v-model="field.options.find(o => o.isDefault).value"
        class="mt-1 block w-full pl-3 pr-10 py-2 bg-white text-[#505050]"
    >
        <option
            v-for="(option, index) in field.options"
            :key="index"
            :value="option.value"
        >
            {{ option.label }}
        </option>
    </select>

    <field-instructions :field="field" position="below-input" />

    <field-label :id="getId()" :field="field" position="below-input" />
</template>

<script>
import FieldMixin from "@src/forms/mixins/FieldMixin";

export default {
    mixins: [FieldMixin],
};
</script>
