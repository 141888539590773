import Address from "@form-components/inputs/Address.vue";
import Agree from "@form-components/inputs/Agree.vue";
import Categories from "@form-components/inputs/Categories.vue";
import Checkboxes from "@form-components/inputs/Checkboxes.vue";
import Date from "@form-components/inputs/Date.vue";
import Dropdown from "@form-components/inputs/Dropdown.vue";
import Email from "@form-components/inputs/Email.vue";
import Entries from "@form-components/inputs/Entries.vue";
import FileUpload from "@form-components/inputs/FileUpload.vue";
import Heading from "@form-components/inputs/Heading.vue";
import Hidden from "@form-components/inputs/Hidden.vue";
import Html from "@form-components/inputs/Html.vue";
import MultiLineText from "@form-components/inputs/MultiLineText.vue";
import Name from "@form-components/inputs/Name.vue";
import Number from "@form-components/inputs/Number.vue";
import Phone from "@form-components/inputs/Phone.vue";
import Radio from "@form-components/inputs/Radio.vue";
import Recipients from "@form-components/inputs/Recipients.vue";
import Section from "@form-components/inputs/Section.vue";
import SingleLineText from "@form-components/inputs/SingleLineText.vue";
import Table from "@form-components/inputs/Table.vue";
import Tags from "@form-components/inputs/Tags.vue";
import Users from "@form-components/inputs/Users.vue";

export default {
    Address,
    Agree,
    Categories,
    Checkboxes,
    Date,
    Dropdown,
    Email,
    Entries,
    FileUpload,
    Heading,
    Hidden,
    Html,
    MultiLineText,
    Name,
    Number,
    Phone,
    Radio,
    Recipients,
    Section,
    SingleLineText,
    Table,
    Tags,
    Users,
};
