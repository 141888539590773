<template>
    <field-label :id="getId()" :field="field" position="above-input" />

    <field-instructions :field="field" position="above-input" />

    <div class="mt-2 space-y-2">
        <input
            type="hidden"
            aria-checked="false"
            aria-hidden="true"
            :name="getName()"
            value=""
        />

        <div class="flex items-center">
            <input
                :id="getId()"
                :name="getName()"
                value="1"
                type="checkbox"
                :required="field.required"
                class="h-4 w-4"
            />

            <label
                :for="getId()"
                class="ml-3 block text-small text-black decorated-link"
                v-html="field.descriptionHtml"
            />
        </div>
    </div>

    <field-instructions :field="field" position="below-input" />

    <field-label :id="getId()" :field="field" position="below-input" />
</template>

<script>
import FieldMixin from "@src/forms/mixins/FieldMixin";

export default {
    mixins: [FieldMixin],
};
</script>
