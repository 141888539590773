<template>
    <component :is="field.displayType" :field="field" />
</template>

<script>
import FieldMixin from "@src/forms/mixins/FieldMixin";

import Checkboxes from "@form-components/inputs/Checkboxes.vue";
import Dropdown from "@form-components/inputs/Dropdown.vue";
import Hidden from "@form-components/inputs/Hidden.vue";
import Radio from "@form-components/inputs/Radio.vue";

export default {
    components: {
        Checkboxes,
        Dropdown,
        Hidden,
        Radio,
    },

    mixins: [FieldMixin],
};
</script>
